// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "output" ]

  connect() {
    this.outputTarget.textContent = 'Hello, Stimulus!'
    var myVideo = document.getElementById("player-id")
    myVideo.play()
  }

  greet() {
    this.outputTarget.textContent = "!"
    var myVideo = document.getElementById("player-id")
    var source = document.getElementById('source-id')
    myVideo.pause()
    source.setAttribute('src','/assets/video2-e514bce95abef94b076e3469a02efdf75fff6fe6ce9978cf37e7fa812446eaa2.mp4')
    myVideo.load()
    myVideo.play()

//    if (myVideo.paused)
//      myVideo.play()
//    else
//      myVideo.pause()
  }
}
